import React, {Component} from 'react';
import Layout from '../components/layout';
import {graphql} from "gatsby";
import MidPageInfoBanner from '../components/mid-page-info-banner';
import JoinNetwork from '../components/join-network';
import GreenHeaderBannerLinks from "../components/green-header-with-banner-links/index";
import InsightsBanner from '../components/insights-banner';
import {IS_MOBILE, CURR_HEADER} from "../common/consts";
import {videoSectionMapper, resourcesTrioMapper, mapGartner} from '../common/utils';
import queryString from 'query-string';
import GartnerStrip from '../components/gartner-strip';

class ServicesLobby extends Component {
    constructor(props) {
        super(props)

        this.banner = React.createRef();

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({...this.state, isMobile: true})
        }

        const params = queryString.parse(window.location.search);

        if('row' in params){
            const scrollHeight = this.banner.current.clientHeight - CURR_HEADER;
            window.scrollTo(0, scrollHeight)
        }

    }

    generateMidPageData = (acf) => {

        const rows = acf.middlePageTextImageRow;

        if(typeof window !== 'undefined' && window.location.search) {
            const params = queryString.parse(window.location.search);
            if('row' in params){
                const index = rows.findIndex((item, index, arr) => item.middlePageAnchorId === params.row);
                const tmp = rows[index];
                rows.splice(0, 0, tmp);
            }
        }

        return rows.map((item, i) => ({
            bannerImg: item.middlePageRowImage ?
                    item.middlePageRowImage : null,
            heading: item.middlePageRowHeadline || "",
            text: item.middlePageRowText || "",
            withCarousel: item.resources && item.resources.length > 0,
            link: item.middlePageLink || null,
            anchorID: item.middlePageAnchorId || null,
            carouselData: item.resources ? item.resources.map((resource) => ({
                link: resource.resourceFields.resourceLink || "",
                img: resource.resourceFields.resourceSmallImage ? resource.resourceFields.resourceSmallImage : null,
                bold: resource.resourceFields.resourceType || "",
                light: resource.title || ""
            })) : null

        }))

    }

    generateButtonLinks = (servicesLobbyAnchorLinks) => {
        return servicesLobbyAnchorLinks.servicesLobbyAnchorLinks.map((item) => ({
            icon: item.servicesMenuIcon ? item.servicesMenuIcon : null,
            title: item.servicesAnchorLabel || "",
            path: `#${item.servicesAnchorId}` || ""
        }))
    }

    render() {
        const {data} = this.props;
        const {wpPage} = data;
        const {headingWithParagraph, servicesLobbyAnchorLinks, tryUsStrip, midPageInfoSection, includeGartner, resourcesTrioFooter, videoSection, pageSeoTitle} = wpPage;

        const infoBanners = midPageInfoSection.middlePageTextImageRow.length ? this.generateMidPageData(midPageInfoSection) : null;

        const buttonLinks = servicesLobbyAnchorLinks.servicesLobbyAnchorLinks ? this.generateButtonLinks(servicesLobbyAnchorLinks) : null;

        const youtube = videoSection && videoSection.videos ? videoSectionMapper(videoSection.videoSectionHeadline, videoSection.videos) : null;

        const coolInsights = resourcesTrioFooter.resourcesTrioResources && resourcesTrioFooter.resourcesTrioResources.length ? resourcesTrioMapper( resourcesTrioFooter.resourcesTrioHeadline, resourcesTrioFooter.resourcesTrioResources) : null;

        const stickyBanner = !wpPage.stickyBanner ? false : {
            label: wpPage.stickyBanner.stickyBannerLabel,
            link: wpPage.stickyBanner.stickyBannerLink,
            goldenLabel: wpPage.stickyBanner.goldenButtonText,
            goldenLink: wpPage.stickyBanner.goldenButtonLink,
        };

        const gartner = includeGartner ? mapGartner({gartnerType: includeGartner.gartnerType, gartnerInclude: includeGartner.gartnerInclude}) : null;

        return (
            <Layout className="services-lobby"
                    lang={this.props.pageContext.lang}
                    breadcrumbs={[{title: wpPage.title, path: this.props.location.pathname}]}
                    whiteMenu={false}
                    yoastData={wpPage.seo || null}
                    seoTitle={pageSeoTitle.pageSeoTitle}
                    title={wpPage.title}
                    wpid={wpPage.databaseId}
                    gartner={gartner}
                    seoPath={this.props.location.pathname}
                    translations={this.props.pageContext.translations}
                    {...{stickyBanner}}
                    {...{youtube}}
            >
                <GreenHeaderBannerLinks
                    headline={headingWithParagraph.headingParagraphHeadline ? headingWithParagraph.headingParagraphHeadline : null}
                    lightText={headingWithParagraph.headingParagraphParagraph ? headingWithParagraph.headingParagraphParagraph : null}
                    bannerLinkLayout="list"
                    bannerLinks={buttonLinks}
                    containerRef={this.banner}
                />
                {infoBanners && infoBanners.map((banner, i) => {
                    return (
                        <>
                        {i === (parseInt((infoBanners.length / 2),10)) && includeGartner.gartnerInclude && includeGartner.gartnerType === 'accordion' &&
                        <GartnerStrip
                            key={'gartner-accordion'}
                            type={'accordion'}
                        />
                        }
                        <MidPageInfoBanner {...banner} key={`min-page-banner-${i}`} imageFirst={Math.abs(i % 2) === 1} offWhite={Math.abs(i % 2) === 1}/>
                        </>
                    )
                })
                }                {!this.state.isMobile &&
                    <JoinNetwork
                        headline={tryUsStrip.tryUsHeadingText ? tryUsStrip.tryUsHeadingText : undefined}
                        buttonText={tryUsStrip.tryUsButtonText ? tryUsStrip.tryUsButtonText : undefined}
                    />
                }
                {coolInsights &&
                <InsightsBanner
                    headline={coolInsights.headline ? coolInsights.headline : null}
                    data={coolInsights.data}
                />
                }
            </Layout>
        )
    }
}

export default ServicesLobby;


export const pageQuery = graphql`
    query servicesLobbyPageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            ...YoastData
            title
            content
            slug
            databaseId
            id
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            headingWithParagraph {
                headingParagraphParagraph
                headingParagraphHeadline
            }
            midPageInfoSection {
                middlePageTextImageRow {
                    middlePageRowHeadline
                    middlePageRowText
                    middlePageRowImage {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                            extension
                            publicURL
                        }
                    }
                    middlePageAnchorId
                    middlePageLink
                    resources {
                        ... on WpResource {
                            id
                            title
                            resourceFields {
                                resourceType
                                resourceShortDescription
                                resourceSmallImage {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 504, quality: 100){
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                        extension
                                        publicURL
                                    }
                                }
                                resourceLink
                            }
                        }
                    }
                }
            }
            tryUsStrip {
                tryUsHeadingText
                tryUsButtonText
            }
            servicesLobbyAnchorLinks {
                servicesLobbyAnchorLinks {
                    servicesMenuIcon {
                        altText
                        localFile {
                                childImageSharp {
                                    fluid(maxWidth: 84){
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                }
                            publicURL
                        }
                    }
                    servicesAnchorLabel
                    servicesAnchorId
                }
            }
            ...StickyBanner
            ...VideoSection
            ...ResourcesTrio
            ...GartnerStrip
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`